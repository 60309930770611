import React from 'react'

import IconBranding from '../svgs/branding.inline.svg'
import IconShare from '../svgs/share.inline.svg'
import IconProperty from '../svgs/property.inline.svg'
import IconGallery from '../svgs/photo-gallery.inline.svg'
import IconMap from '../svgs/map.inline.svg'
import IconLeads from '../svgs/leads.inline.svg'

export const features = [
  {
    id: `f_1`,
    title: `Branded Pages`,
    desc: `Every property page is color-matched to make sure it works with your branding. Your logo and color schemes make the page look professional, like your own web developer coded it.`,
    icon: <IconBranding />,
  },
  {
    id: `f_2`,
    title: `Property Details`,
    desc: `Every page comes fully loaded with the information that your prospects need - without you having to do any data entry. The layout of property amenities is easy to consume and inviting.`,
    icon: <IconProperty />,
  },
  {
    id: `f_3`,
    title: `Beautiful Photo Galleries`,
    desc: `Beyond video, nothing is more inviting than beautiful photo galleries. Given the work that went into capturing the photos, our property pages showcase these photos the right way.`,
    icon: <IconGallery />,
  },
  {
    id: `f_4`,
    title: `Interactive Map`,
    desc: `Every property page comes with integrated mapping (without any need to code anything) so that your prospects can get everything they need without you having to lift a finger.`,
    icon: <IconMap />,
  },
  {
    id: `f_5`,
    title: `Lead Forms`,
    desc: `Your property page wouldn’t be complete without a lead capture form, and every one of our property pages come with them - even for sold properties, so that you never miss a lead.`,
    icon: <IconLeads />,
  },
  {
    id: `f_6`,
    title: `Easy to Share`,
    desc: `Every property page comes with quick and easy ways for people to share them to the most common social media platforms, from Pinterest to Twitter to Facebook or Instagram.`,
    icon: <IconShare />,
  },
]
