import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'

// Data
import { features } from '../data/property-page-features'

// Internal dependencies
import Layout from '../components/layout'
import SEO from '../components/seo'
import StructuredData from '../components/structured-data'
import CreateShowCta from '../components/ctas/create-show'

function PropertyPagesPage(props) {
  const { data } = props

  return (
    <Layout flush={true}>
      <SEO title="Property Pages" slug="/property-pages" />
      <StructuredData
        pageType="webPage"
        slug="property-pages"
        pageTitle="Property Pages"
      />
      <div className="bg-purple-200 pb-10 lg:pb-0 lg:mt-24 mb-16 lg:mb-32">
        <div className="container flex flex-col lg:flex-row relative">
          <div
            className="sm:order-2 lg:absolute right-0 z-20 lg:w-1/2 transform lg:-translate-y-1/2"
            style={{
              top: `50%`,
            }}
          >
            <Img
              alt="device screen of product page"
              fluid={data.hero.childImageSharp.fluid}
            />
          </div>
          <div className="text-center lg:text-left py-10 lg:py-20 lg:w-1/2 lg:pr-24">
            <h1 className="text-3xl lg:text-4xl leading-tight mb-6 lg:mb-10 text-purple-900">
              A Dedicated Property Page without a Dedicated Developer
            </h1>
            <p className="md:text-lg">
              You only get one “first impression” and while the MLS is awesome
              at a ton of things, a beautiful single property website to capture
              leads isn’t really one of them.
            </p>
          </div>
        </div>
      </div>

      <div className="container mb-10">
        <ul className="grid md:grid-cols-2 gap-16 md:gap-10">
          {features &&
            features.map(feature => (
              <li
                className="flex flex-col lg:flex-row text-center lg:text-left"
                key={feature.id}
              >
                <div className="w-12  flex-shrink-0 mx-auto lg:mr-10 lg:ml-0 mb-4 lg:mb-0">
                  {feature.icon}
                </div>
                <div>
                  <h2 className="text-xl">{feature.title}</h2>
                  <div className="mx-auto lg:mx-0 w-10 mt-2 mb-4 h-1 rounded-full bg-purple-200"></div>
                  <p className="text-lg">{feature.desc}</p>
                </div>
              </li>
            ))}
        </ul>
      </div>

      <div className="py-10 px-4 lg:py-20">
        <CreateShowCta
          header="Ready to Get Started?"
          buttonText="Create Your First Show"
          buttonUrl="https://app.realestateshows.com/listings/preview"
        />
      </div>
    </Layout>
  )
}

PropertyPagesPage.propTypes = {
  data: PropTypes.object,
}

export default PropertyPagesPage

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "prop-hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 640, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
